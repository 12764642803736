// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-post-js": () => import("/opt/build/repo/src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tags-page-js": () => import("/opt/build/repo/src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-tag-post-js": () => import("/opt/build/repo/src/templates/tag-post.js" /* webpackChunkName: "component---src-templates-tag-post-js" */),
  "component---src-templates-video-page-js": () => import("/opt/build/repo/src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-js": () => import("/opt/build/repo/src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

